@use './breakpoints.module' as *;
@use './helpers.scss' as *;

$headerHeight: pxToRem(64);

$padding-horizontal: pxToRem(20);

$max-width-section-sm: pxToRem(1072);
$max-width-section: pxToRem(1276);
$max-width-section-xl: pxToRem($max-width-xl);

/// Standard spacing for hygiene page sections.
@mixin sectionHorizontalSpacing($maxWidth: $max-width-section-xl) {
  max-width: calc(#{$maxWidth} + 2 * #{$padding-horizontal});
  padding-left: $padding-horizontal;
  padding-right: $padding-horizontal;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  overflow-x: clip;

  @include md {
    overflow-x: visible;
  }
}

@mixin sectionContentHub {
  @include sectionHorizontalSpacing($max-width-section-sm);
}

@mixin sectionContentHubArticle {
  padding-left: pxToRem(20);
  padding-right: pxToRem(20);
  margin: auto;
  max-width: pxToRem(720);
  box-sizing: border-box;

  @include xl {
    max-width: pxToRem(888);
  }
}

//Common padding for all components inside FlexibleSectionManager (except for the ones with full-width/height or fullsize images)
@mixin sectionVerticalSpacing {
  --section-vertical-padding: #{pxToRem(32)};
  padding-bottom: var(--section-vertical-padding);
  padding-top: var(--section-vertical-padding);

  :global(.RichText) & {
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: var(--section-vertical-padding);
    margin-top: var(--section-vertical-padding);
  }

  @include sm {
    --section-vertical-padding: #{pxToRem(40)};
  }

  @include md {
    --section-vertical-padding: #{pxToRem(48)};
  }

  @include lg {
    --section-vertical-padding: #{pxToRem(56)};
  }

  @include xl {
    --section-vertical-padding: #{pxToRem(64)};
  }
}

/// Mixin to add a background colour to a section with max-width
///
/// @param {string} $color
@mixin fullWidthBackgroundColor($color) {
  --section-background: #{$color};
  position: relative;
  background-color: var(--section-background);

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    background-color: var(--section-background);
  }

  & > * {
    position: relative;
  }
}

/// Mixin for a root section
@mixin rootSection($color: var(--bac-tie1)) {
  padding-left: pxToRem(16);
  padding-right: pxToRem(16);
  margin: auto;
  box-sizing: border-box;
  max-width: pxToRem($max-width-xl + 64);
  @include fullWidthBackgroundColor($color);

  @include sm {
    padding-left: pxToRem(32);
    padding-right: pxToRem(32);
  }
}

@mixin rootContainer() {
  padding-left: pxToRem(16);
  padding-right: pxToRem(16);
  margin: auto;
  box-sizing: border-box;
  max-width: pxToRem($max-width-xl + 64);

  @include sm {
    padding-left: pxToRem(32);
    padding-right: pxToRem(32);
  }
}
