@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  text-decoration: none;
  color: var(--tex-def-tie1);
  background: var(--for-neu-tie1);
  border: pxToRem(2) solid var(--bor-neu-bas);
  height: pxToRem(40);
  border-radius: pxToRem(20);
  transition: all ease-in-out 200ms;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 pxToRem(12);
  cursor: pointer;

  &:hover,
  &:focus-visible {
    border-color: var(--bor-neu-emp);
  }

  .icon {
    pointer-events: none;
    margin: 0 pxToRem(-4) 0 pxToRem(6);
  }

  small {
    text-transform: none;
    font-size: inherit;
    line-height: inherit;
  }

  :global(.shade:before) {
    display: none;
  }
}
