@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

$headerHeight: pxToRem(64);

.root {
  display: none;
  position: fixed;
  top: $headerHeight;
  left: 0;
  width: 100%;
  background: var(--bac-tie1);
  z-index: $zIndex-header;
  border-bottom: $border-0;
  box-shadow: var(--elevation-0dp);
  transition:
    transform ease-in-out 200ms,
    opacity ease-in-out 200ms;

  :global(body.scroll-down) & {
    transform: translate3d(0, -$headerHeight, 0);
  }

  &.visible {
    display: block;
  }
}

@include md {
  .root {
    &,
    &.visible {
      display: none;
    }
  }
}
