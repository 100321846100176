@use 'src/theme/helpers' as *;
@use 'src/theme/color.module.scss' as *;

.helperText {
  position: absolute;
  left: pxToRem(20);
  bottom: pxToRem(16);
  margin: 0;
  @include fontSize(14, 18);
  letter-spacing: pxToRem(0.2);
  color: var(--tex-def-tie2);
}

textarea.hasMaxLength {
  padding-bottom: pxToRem(24);
}
