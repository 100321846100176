@use './helpers' as *;

$screen-sm: 580;
$screen-smp: 720;
$screen-md: 960;
$screen-mdp: 1136;
$screen-lg: 1440;
$screen-xl: 1600;

/// Mixing for small screens (min-width #{$screen-sm})
@mixin sm {
  @media (min-width: #{pxToRem($screen-sm)}) {
    @content;
  }
}

/// Mixing for small+ screens (min-width #{$screen-smp})
@mixin smp {
  @media (min-width: #{pxToRem($screen-smp)}) {
    @content;
  }
}

/// Mixing for medium screens (min-width #{$screen-md})
@mixin md {
  @media (min-width: #{pxToRem($screen-md)}) {
    @content;
  }
}

/// Mixing for medium+ screens (min-width #{$screen-lmd})
@mixin mdp {
  @media (min-width: #{pxToRem($screen-mdp)}) {
    @content;
  }
}

/// Mixing for large screens (min-width #{$screen-lg})
@mixin lg {
  @media (min-width: #{pxToRem($screen-lg)}) {
    @content;
  }
}

/// Mixing for extra large screens (min-width #{$screen-xl})
@mixin xl {
  @media (min-width: #{pxToRem($screen-xl)}) {
    @content;
  }
}

/// Mixing for small screens in landscape (min-width #{$screen-sm})
@mixin sml {
  @media (max-height: #{pxToRem($screen-sm)})
    and (orientation: landscape) {
    @content;
  }
}
