@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;

.root {
  position: relative;
  background-color: var(--bac-tie2);
  padding-bottom: pxToRem(40);
  min-height: 100vh;
}

.contentContainer {
  position: relative;
}

.titleContainer {
  background-color: var(--bac-tie1);
  padding-bottom: pxToRem(12);
}

.carDetailsContainer {
  background-color: var(--bac-tie1);
  padding-bottom: pxToRem(16);
}

.title {
  @include fontSize(20, 28);
  font-weight: 800;
  color: var(--tex-def-tie1);
  margin: 0;
  text-align: center;
}

.subtitle {
  @include fontSize(15, 20);
  color: var(--tex-def-tie2);
  text-align: center;
  justify-self: center;
  max-width: pxToRem(259);
  margin: auto;
}

.viewTermsButtonContainer {
  text-align: center;
  margin: 0 0 pxToRem(16);

  .viewTermsButton {
    padding: 0;
    margin: 0;
    @include fontSize(12, 16);
    color: $color-uiPrimary-600;
    font-weight: 400;
  }
}

.termsModalContent {
  p {
    @include fontSize(15, 20);
    color: var(--tex-def-tie2);
    font-weight: 400;

    a {
      font-weight: 800;
      color: $color-uiPrimary-600;
    }

    &:has(strong) {
      color: var(--tex-def-tie1);
      font-weight: 800;
      margin: pxToRem(24) 0 pxToRem(4);

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.desktopOnly {
  display: none;
}

@include sm {
  .contentContainer {
    padding: 0 pxToRem(16);
  }

  .viewTermsButtonContainer {
    margin: pxToRem(12) 0 pxToRem(56);
  }

  .titleContainer {
    background-color: transparent;
    padding: pxToRem(24) 0;
  }

  .title {
    @include fontSize(32, 40);
    text-align: start;
  }

  .subtitle {
    @include fontSize(20, 28);
    text-align: start;
    max-width: 100%;
    margin-top: pxToRem(4);
  }

  .gridContainer {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-columns: 1fr 1fr;
    column-gap: pxToRem(30);
  }

  .desktopOnly {
    display: block;
  }

  .mobileOnly {
    display: none;
  }
}

@include md {
  .contentContainer {
    max-width: max-content;
    margin: auto;
    padding: 0;
  }

  .gridContainer {
    grid-template-columns: 25rem 25rem;
    column-gap: pxToRem(50);
  }
}
