@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

.root {
  z-index: 1;
  background: var(--bac-tie1);
}

.header {
  display: none;
}

.content {
  padding: pxToRem(8) 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: pxToRem(16);
}

.footer {
  --offset: #{pxToRem(20)};
  background: var(--bac-tie2);
  padding: pxToRem(16) var(--offset);
  margin: 0 calc(-1 * var(--offset));
  border-top: $border-0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@include sm {
  .footer {
    --offset: #{pxToRem(32)};
  }
}

@include md {
  .root {
    z-index: $zIndex-headerBelow;
    position: sticky;
    top: pxToRem(64);
    left: 0;
    width: 100%;
    border-bottom: $border-0;
    box-shadow: var(--elevation-0dp);
  }

  .container {
    display: grid;
    grid-template:
      '😀 🚗' 1fr
      '👠 🚗' auto / 1fr 2fr;
    padding-top: pxToRem(16);
    padding-bottom: pxToRem(16);
  }

  .header {
    display: block;
    grid-area: 😀;
  }

  .content {
    grid-area: 🚗;
    padding: 0;
  }

  .footer {
    --offset: 0;
    background: initial;
    border-top: none;
    grid-area: 👠;
    justify-content: flex-start;
  }
}

@include lg {
  .root {
    padding-top: pxToRem(8);
    padding-bottom: pxToRem(8);
  }
}
