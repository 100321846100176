@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  overflow: hidden;
  color: var(--tex-def-tie1);
  @include fontSize(15, 20);

  &.default {
    padding-bottom: pxToRem(16);

    .imageContainer {
      margin-bottom: pxToRem(10);
      border-radius: var(--cor_rad-xs);
    }

    .infoContainer,
    .priceContainer {
      padding: pxToRem(10) pxToRem(8) 0;
    }
  }

  &.card {
    border-radius: var(--cor_rad-xs);
    box-shadow: var(--elevation-2dp);
    background: var(--bac-tie1);
    padding-bottom: pxToRem(24);

    .infoContainer,
    .priceContainer {
      padding: pxToRem(16) pxToRem(28) 0;
    }

    .infoContainer {
      text-align: center;
    }

    .title {
      @include fontSize(26, 32);
    }

    .carInfo {
      @include fontSize(17, 24);
    }

    .priceValue,
    .priceLabel {
      @include fontSize(20, 28);
      font-weight: 700;
    }

    .priceGstLabel {
      @include fontSize(14, 18);
    }
  }
}

.imageContainer {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: percentage(calc(273 / 512));
}

.title {
  @include fontSize(20, 28);
  font-weight: 800;
  margin: 0;
}

.carInfo {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.carInfoItem {
  margin-right: pxToRem(8);

  &:before {
    content: '•';
    margin-right: pxToRem(8);
    display: inline-block;
  }

  &:first-child:before {
    content: none;
  }

  &:last-child {
    margin-right: 0;
  }
}

.priceContainer {
  display: grid;
  grid-template-columns: auto auto;
  margin: 0;
  column-gap: pxToRem(8);
}

.priceValue {
  text-align: right;
  margin: 0;
}

.priceValue {
  @include fontSize(17, 24);
  font-weight: 700;
}

.priceGstLabel {
  color: $color-uiPrimary-600;
  letter-spacing: pxToRem(0.2);
  font-weight: 800;
  cursor: pointer;
  width: fit-content;
  grid-column-start: 1;
  grid-column-end: 3;
}

@include sm {
  .root.default {
    display: grid;
    grid-template:
      '🌅 🌅' auto
      '🎛 💰' auto / 1fr 1fr;
    column-gap: pxToRem(16);
    row-gap: pxToRem(16);
    @include fontSize(17, 26);

    .imageContainer {
      grid-area: 🌅;
      margin: 0;
    }

    .infoContainer {
      grid-area: 🎛;
    }

    .priceContainer {
      grid-area: 💰;
    }

    .title {
      @include fontSize(26, 32);
    }

    .priceLabel,
    .priceValue {
      @include fontSize(20, 28);
      font-weight: 800;
    }

    .priceGstLabel {
      @include fontSize(14, 18);
    }
  }
}

@include md {
  .root.default {
    column-gap: pxToRem(52);
  }
}
