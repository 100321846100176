@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

$boxSize: pxToRem(24);
$size: pxToRem(18);
$transition: all ease-in-out 100ms;

/**
 * Please note, the focus/hover styles are managed within the parent component
 */

.root {
  width: $boxSize;
  height: $boxSize;
  display: block;
  position: relative;
}

.checkbox,
.radio {
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    transition: $transition;
    display: block;
  }

  &::before {
    width: $size;
    height: $size;
    background: var(--bac-tie1);
    border: pxToRem(1.2) solid var(--for-pri-bas);
  }

  &::after {
    opacity: 0;
    width: pxToRem(1);
    height: pxToRem(1);
  }

  &.error {
    &::before {
      border-color: $color-sentimental-error-600;
      box-shadow: 0 0 0 pxToRem(2) $color-sentimental-error-100;
    }
  }

  &.disabled {
    mix-blend-mode: multiply;
    opacity: 0.4;
  }
}

/************************
 * Styles for a checkbox
 ************************/

.checkbox {
  &::before {
    border-radius: pxToRem(2);
  }

  &:not(.indeterminate)::after {
    width: pxToRem(12);
    height: pxToRem(6);
    border-left: pxToRem(2) solid $color-neutral-000;
    border-bottom: pxToRem(2) solid $color-neutral-000;
    border-radius: pxToRem(1);
    transform: translate(-50%, -70%) rotate(-45deg);
  }

  &.indeterminate,
  &.checked {
    // Overwrite the border color for error
    &.error::before {
      border-color: var(--for-pri-bas);
    }
  }

  &.indeterminate {
    &::before {
      background: var(--for-pri-bas);
    }

    &::after {
      width: pxToRem(8);
      border-bottom: pxToRem(1.5) solid $color-neutral-000;
      opacity: 1;
    }
  }

  &.checked {
    &::before {
      background: var(--for-pri-bas);
    }

    &::after {
      opacity: 1;
    }
  }
}

/************************
 * Styles for a radio
 ************************/

.radio {
  &::before {
    $size: pxToRem(20);
    width: $size;
    height: $size;
    border-radius: 50%;
  }

  &::after {
    $size: pxToRem(12);
    width: $size;
    height: $size;
    background: var(--for-pri-bas);
    border-radius: 50%;
  }

  &.checked {
    &::before {
      border-color: var(--for-pri-bas);
    }

    &::after {
      opacity: 1;
    }
  }
}

/************************
 * Styles for a switch
 ************************/
.switch {
  position: relative;
  width: pxToRem(44);
  height: pxToRem(24);
  border-radius: pxToRem(100);
  background: var(--for-neu-tie3);

  &:not(.indeterminate) {
    background: var(--for-neu-tie3);
  }

  &.indeterminate,
  &.checked {
    background: var(--for-pri-bas);
  }

  &.error {
    outline: pxToRem(1.5) solid var(--bor-err-bas);
  }

  &.disabled {
    mix-blend-mode: multiply;
    opacity: 0.4;
  }
}

.thumb {
  --color-thumb-icon: var(--ico-def-tie1);
  position: absolute;
  top: pxToRem(2);
  left: pxToRem(2);
  width: pxToRem(20);
  height: pxToRem(20);
  border-radius: var(--cor_rad-ful);
  background: var(--for-neu-tie1);
  box-shadow: var(--elevation-0dp);
  transition:
    left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: pxToRem(1);
    height: pxToRem(13);
    background: var(--color-thumb-icon);
  }

  &:not(.checked)::before {
    transform: translate(0.5625rem, 0.21875rem) rotate(45deg);
    background: var(--color-thumb-icon);
  }

  &:not(.checked)::after {
    transform: translate(0.5625rem, 0.21875rem) rotate(-45deg);
    background: var(--color-thumb-icon);
  }

  &.checked {
    transform: translateX(20px);
    &::before {
      background: var(--color-thumb-icon);
      transform: translate(0.75rem, 0.25rem) rotate(45deg) scaleY(0.7);
    }

    &::after {
      background: var(--color-thumb-icon);
      transform: translate(0.4375rem, 0.3125rem) rotate(-45deg) scaleY(0.45);
    }
  }
}
