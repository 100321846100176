@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

$inputTopPadding: pxToRem(16);
$inputTopPaddingSmall: pxToRem(17);
$inputBottomPaddingSmall: pxToRem(15);
$inputHorizontalPadding: pxToRem(20);
$labelTransition: ease-in-out 200ms;
$inputBottomMargin: pxToRem(24);

.root {
  display: block;
  position: relative;
  margin-top: 0;
  margin-bottom: $inputBottomMargin;
}

.wrapper {
  &:not(.disabled) {
    &.error {
      .inputWrapper {
        border-color: $color-sentimental-error-600;
        box-shadow:
          0 0 0 pxToRem(1) $color-sentimental-error-600,
          var(--elevation-1dp);
      }
    }
  }

  &.disabled {
    color: var(--tex-def-tie3);

    .inputWrapper {
      border-color: $color-neutral-300;
      background: $color-neutral-300;
    }

    .label {
      color: inherit;
    }
  }

  &.noLabel {
    input {
      padding-top: 0;
      padding-bottom: 0;

      &::placeholder {
        opacity: 1;
      }
    }
  }

  &.small {
    min-height: pxToRem(56);
  }

  &.large {
    min-height: pxToRem(86);
  }

  // Display the label's chevron
  &:global(.selectWrapper) {
    .inputWrapper:after {
      content: '';
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      border-bottom: 1px solid currentColor;
      border-left: 1px solid currentColor;
      border-bottom-left-radius: 2px;
      transform: rotate(-45deg) translate(0%, 0%);
      transform-origin: center center;
      transition: transform ease-in-out 200ms;
      position: absolute;
      top: calc(50% - #{pxToRem(7)});
      right: pxToRem(16);
    }
  }

  // Reduce the size of the label
  &:not(.labelOutside) {
    &:focus-within,
    &.filled,
    &:has(input:not(:placeholder-shown)) {
      .label {
        transform: translateY(pxToRem(-8)) scale(0.8);
      }

      option {
        color: var(--tex-def-tie1);
        cursor: pointer;
        padding: pxToRem(12) pxToRem(16);
      }
    }
  }

  // Variant for label outside
  &.labelOutside {
    .label {
      display: block;
      position: relative;
      top: unset;
      left: unset;
      color: var(--tex-def-tie1);
      font-weight: 800;
      @include fontSize(17, 24);
      margin-bottom: pxToRem(8);
      margin-left: pxToRem(2);
      transform: none;
    }

    .inputWrapper {
      min-height: pxToRem(54);

      input {
        padding-top: 0;

        &::placeholder {
          opacity: 1;
        }
      }
    }
  }
}

.tagInputWrapper {
  display: flex;
  flex-direction: column;
  gap: pxToRem(4);
}

.inputWrapper {
  @include fontSize(17, 27);
  display: block;
  position: relative;
  box-sizing: border-box;
  min-height: pxToRem(60);
  border-radius: var(--cor_rad-xs);
  border: thin solid $color-neutral-100;
  box-shadow: var(--elevation-1dp);
  transition: all ease-in-out 100ms;
  color: var(--tex-def-tie1);
  background: var(--bac-tie1);
  isolation: isolate;
  overflow: hidden;
  outline: none;

  &:hover {
    border-color: var(--bor-pri-bas);
    box-shadow:
      0 0 0 pxToRem(1) var(--bor-pri-bas),
      var(--elevation-1dp);
  }

  &:focus-within {
    border-color: var(--bor-pri-bas);
    box-shadow:
      0 0 0 pxToRem(3) var(--bor-pri-bas),
      var(--elevation-1dp);
  }

  input {
    font: inherit;
    color: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: $inputTopPadding $inputHorizontalPadding 0;
    margin: 0;
    border: none;
    box-sizing: border-box;
    appearance: none;
    background: transparent;

    &::placeholder {
      opacity: 0;
      transition: opacity $labelTransition;
      color: var(--tex-def-tie3);
    }

    &:focus-visible {
      outline: none;

      &::placeholder {
        opacity: 1;
      }
    }
  }
}

.label {
  @include fontSize(17, 27);
  font-weight: 800;
  color: var(--tex-def-tie2);
  position: absolute;
  top: pxToRem(16);
  left: $inputHorizontalPadding;
  pointer-events: none;
  transform-origin: top left;
  transition: transform $labelTransition;
  z-index: 1;

  + .subLabel {
    margin-top: pxToRem(-4);
  }
}

.subLabel {
  @include fontSize(15, 20);
  font-weight: normal;
  display: block;
  margin-top: pxToRem(4);
  margin-bottom: pxToRem(8);

  ul,
  ol {
    margin: 0 0 pxToRem(8);
    padding-left: pxToRem(20);
  }
}

.note {
  @include fontSize(14, 18);
  color: var(--tex-def-tie3);
  display: block;
  margin-top: pxToRem(4);

  a {
    color: var(--tex-def-tie1);
  }

  &.error {
    color: var(--tex-err-tie1);

    a {
      color: $color-sentimental-error-800;
    }
  }
}

@include sm {
  .inputWrapper {
    min-width: pxToRem(240);
  }
}

@include md {
  .tagInputWrapper {
    flex-direction: column-reverse;
    gap: 0;
  }
}
