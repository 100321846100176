@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  // Allow the modal to display above the checkout header
  &:global.MuiDialog-root {
    z-index: 1400 !important;
  }
}
