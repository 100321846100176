/** Legacy colors, please remove at earliest convenience */
$color-neutral-000: var(--color-neutral-000);
$color-neutral-050: var(--color-neutral-050);
$color-neutral-075: var(--color-neutral-075);
$color-neutral-100: var(--color-neutral-100);
$color-neutral-150: var(--color-neutral-150);
$color-neutral-200: var(--color-neutral-200);
$color-neutral-300: var(--color-neutral-300);
$color-neutral-400: var(--color-neutral-400);
$color-neutral-500: var(--color-neutral-500);
$color-neutral-600: var(--color-neutral-600);
$color-neutral-700: var(--color-neutral-700);
$color-neutral-800: var(--color-neutral-800);
$color-neutral-900: var(--color-neutral-900);

$color-uiPrimary-000: var(--color-uiPrimary-000);
$color-uiPrimary-025: var(--color-uiPrimary-025);
$color-uiPrimary-050: var(--color-uiPrimary-050);
$color-uiPrimary-100: var(--color-uiPrimary-100);
$color-uiPrimary-200: var(--color-uiPrimary-200);
$color-uiPrimary-300: var(--color-uiPrimary-300);
$color-uiPrimary-400: var(--color-uiPrimary-400);
$color-uiPrimary-500: var(--color-uiPrimary-500);
$color-uiPrimary-600: var(--color-uiPrimary-600);
$color-uiPrimary-700: var(--color-uiPrimary-700);
$color-uiPrimary-800: var(--color-uiPrimary-800);
$color-uiPrimary-900: var(--color-uiPrimary-900);

$color-brandPrimary-050: var(--color-brandPrimary-050);
$color-brandPrimary-100: var(--color-brandPrimary-100);
$color-brandPrimary-300: var(--color-brandPrimary-300);

$color-sentimental-info-050: var(--color-sentimental-info-050);
$color-sentimental-info-100: var(--color-sentimental-info-100);
$color-sentimental-info-200: var(--color-sentimental-info-200);
$color-sentimental-info-600: var(--color-sentimental-info-600);
$color-sentimental-info-700: var(--color-sentimental-info-700);
$color-sentimental-info-800: var(--color-sentimental-info-800);

$color-sentimental-success-050: var(--color-sentimental-success-050);
$color-sentimental-success-100: var(--color-sentimental-success-100);
$color-sentimental-success-200: var(--color-sentimental-success-200);
$color-sentimental-success-500: var(--color-sentimental-success-500);
$color-sentimental-success-600: var(--color-sentimental-success-600);
$color-sentimental-success-700: var(--color-sentimental-success-700);
$color-sentimental-success-800: var(--color-sentimental-success-800);

$color-sentimental-caution-100: var(--color-sentimental-caution-100);
$color-sentimental-caution-200: var(--color-sentimental-caution-200);
$color-sentimental-caution-800: var(--color-sentimental-caution-800);

$color-sentimental-error-050: var(--color-sentimental-error-050);
$color-sentimental-error-100: var(--color-sentimental-error-100);
$color-sentimental-error-600: var(--color-sentimental-error-600);
$color-sentimental-error-700: var(--color-sentimental-error-700);
$color-sentimental-error-800: var(--color-sentimental-error-800);

$color-opacity-light-highEmphasis: var(--color-opacity-light-highEmphasis);
$color-opacity-light-mediumEmphasis: var(--color-opacity-light-mediumEmphasis);
$color-opacity-light-lowEmphasis: var(--color-opacity-light-lowEmphasis);
$color-opacity-light-veryLowEmphasis: var(--color-opacity-light-veryLowEmphasis);

$color-overlay: var(--color-overlay);

$border-0: thin solid var(--bor-neu-mut);
$border-1: 2px solid var(--bor-pri-bas);
$border-2: thin solid $color-neutral-100;
$border-3: 2px solid $color-sentimental-error-600;
$border-4: thin solid $color-sentimental-error-600;
$border-5: thin solid $color-uiPrimary-400;
$border-6: 2px solid $color-sentimental-success-500;

$color-star: #ffa800;
