$max-width-xl: 1600;

/// Transform a value in px to rem
///
/// @param {number} $value
/// @return {string} value in rem
@function pxToRem($value) {
  @return calc($value / 16) + rem;
}

/// Transform a unitless value to its equivalent in pixels.
///
/// @param {number} $value
/// @return {px} value in pixels
@function numToPx($value) {
  @return $value * 1px;
}

/// Polyfill for native CSS function clamp, as it isn't supported on some older browsers.
/// See https://developer.mozilla.org/en-US/docs/Web/CSS/clamp for details.
@function clampValue($min, $value, $max) {
  @return max($min, min($value, $max));
}

/// Mixin to easily output font size and line height
///
/// @param {number} $fontSize the font size in pixels
/// @param {number} $lineHeight the line height in pixels
@mixin fontSize($fontSize, $lineHeight) {
  font-size: pxToRem($fontSize);
  line-height: calc($lineHeight / $fontSize);
}

/// Mixin to easily align numbers thanks to the font variant numeric
@mixin fontNumeric() {
  font-variant-numeric: tabular-nums;
}

@mixin maskedBackground($color) {
  &::before {
    content: '';
    background: $color;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: #{pxToRem(0)};
    left: #{pxToRem(0)};
    transition: all 0.2s ease-in-out;
  }
}
