@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  padding: pxToRem(16) pxToRem(16);
  border-radius: var(--cor_rad-lg);
  background: var(--bac-tie1);
  border: $border-2;
  margin: pxToRem(16) 0;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: pxToRem(24);
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: pxToRem(8);
}

.title {
  margin: 0;
  @include fontSize(20, 28);
  font-weight: 800;
  color: var(--tex-def-tie1);
  &::first-letter {
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }
}

.subTitle {
  margin: 0;
  @include fontSize(15, 20);
  font-weight: 400;
  color: var(--tex-def-tie2);

  div {
    display: inline-block;

    &::first-letter {
      text-transform: uppercase;
    }
    &:after {
      content: ',';
      margin-right: 0.2em;
    }

    &:last-child:after {
      content: none;
    }
  }
}

.switchWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: pxToRem(2) pxToRem(4);
  padding-right: pxToRem(0);
  border-radius: var(--cor_rad-xs);
  transition: background linear 150ms;
  width: 100%;

  :global(.wrapper--switch) {
    width: 100%;
  }
}

.switch {
  margin-bottom: 0px !important;

  :global(.labelWrapper) {
    display: block;
  }
}

@include md {
  .switchWrapper {
    padding: pxToRem(8) pxToRem(16) pxToRem(8) pxToRem(16);
  }
}

@include md {
  .root {
    padding: pxToRem(32) pxToRem(16);
  }
  .titleContainer {
    padding-left: pxToRem(16);
    padding-right: pxToRem(16);
  }
}
