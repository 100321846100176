@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.container {
  display: flex;
  flex-direction: column;
}

.processingFeeModal {
  :global {
    .MuiDialog-scrollPaper {
      .MuiDialog-paper {
        section {
          padding-bottom: 0;
        }

        header {
          padding-bottom: pxToRem(8);
          padding-top: pxToRem(16);
        }
      }
    }
  }
}

.listBackCardWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: pxToRem(16);
  padding-bottom: pxToRem(16);
}

.creditDescription {
  display: flex;
  align-items: center;

  .creditIcon {
    width: pxToRem(38);
    height: pxToRem(24);
  }
}

.separator {
  background-color: $color-neutral-300;
  width: 100%;
  height: pxToRem(1);
  margin: pxToRem(4) 0;
}

.creditName {
  align-self: stretch;
  margin-left: pxToRem(12);
  @include fontSize(17, 22);
}

.creditFee {
  @include fontSize(17, 22);
}

.backTransferDescription {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: pxToRem(16);
  padding-bottom: pxToRem(16);
}

.backTranferText {
  @include fontSize(17, 22);
}

@include md {
  .processingFeeModal {
    :global {
      .MuiDialog-scrollPaper {
        .MuiDialog-paper {
          header {
            padding-bottom: pxToRem(4);
            padding-top: pxToRem(20);
          }
        }
      }
    }
  }

  .listBackCardWrapper:last-child {
    padding-bottom: pxToRem(8);
  }
}
