@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr;
  row-gap: pxToRem(10);
}

.rangeToggleContainer {
  padding-bottom: pxToRem(24);
}

.priceRoot .rangeToggle {
  padding-top: pxToRem(2);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: pxToRem(38);
  column-gap: pxToRem(8);
  padding-bottom: pxToRem(24);
  border-radius: var(--cor_rad-md);
  height: auto;

  .rangeToggleActive:global(.button) {
    background: var(--for-pri-lig);
    color: var(--tex-def-tie1);
    border-color: var(--bor-pri-bas);
    box-shadow: inset 0 0 0 pxToRem(1) var(--bor-pri-bas);
  }

  .rangeToggleInactive:global(.button) {
    border-color: var(--bor-neu-mut);
  }
}

.selectRange:not(:first-child) {
  margin-top: pxToRem(8);
}
