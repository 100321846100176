@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.listCardContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: pxToRem(16);
  border-radius: var(--cor_rad-lg);
  background: var(--for-neu-tie2);
  padding: pxToRem(24);
}

.modalWrapper {
  :global {
    .MuiDialog-scrollPaper {
      .MuiDialog-paper {
        section {
          padding-bottom: 0;
          header {
            padding-top: pxToRem(16);
            padding-bottom: pxToRem(16);
            margin-bottom: pxToRem(16);
          }
        }
      }
    }
  }
}

.separator {
  background-color: $color-neutral-300;
  width: 100%;
  height: pxToRem(1);
}

.financeValue {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.financeProviderLogo {
  display: inline-block;
  margin-top: pxToRem(4);
}

@include md {
  .modalWrapper {
    :global {
      .MuiDialog-scrollPaper {
        .MuiDialog-paper {
          section {
            header {
              border-bottom: pxToRem(1) solid $color-neutral-300;
              padding-top: pxToRem(20);
              padding-bottom: pxToRem(20);
            }
          }
        }
      }
    }
  }
}
