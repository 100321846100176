@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: pxToRem(8);
  text-decoration: none;
  color: inherit;
}
