@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  background: var(--for-neu-tie1);
  border: $border-0;
  border-radius: var(--cor_rad-lg);
  box-shadow: var(--elevation-0dp);
  overflow: hidden;
  position: sticky;
  top: calc($headerHeight + pxToRem(16)); // header height + padding top
  max-height: calc(100vh - ($headerHeight + pxToRem(32))); // header height + padding top + padding bottom
  display: grid;
  grid-template:
    '😀' pxToRem(72)
    '🎽' 1fr / 1fr;
}

.header {
  grid-area: 😀;
  padding: pxToRem(16);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.content {
  grid-area: 🎽;
  position: relative;
  overflow: hidden;

  :global {
    .filtersWrapper {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      border-top: 1px solid transparent;

      &.overflow-top {
        border-top: $border-0;
      }
    }
  }
}

.resetButtonWrapper {
  position: absolute;
  top: pxToRem(16);
  right: pxToRem(16);
}
