@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;

.container {
  display: grid;
  row-gap: pxToRem(12);
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  @include fontSize(17, 24);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.description {
  color: var(--tex-def-tie2);
}

.rateInput {
  width: pxToRem(110);

  :global {
    .MuiFormControl-root {
      .MuiFormLabel-root {
        display: none;
      }
      .MuiInputBase-root {
        input {
          padding-top: pxToRem(16) !important;
          padding-bottom: pxToRem(16) !important;
          padding-right: 6px;
          text-align: center;
          @include fontSize(17, 24);
        }
      }
    }
  }
}
