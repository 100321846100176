@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;

.root {
  background: var(--bac-tie1);
  padding-bottom: pxToRem(56);
  width: 100%;
}

.header {
  padding-bottom: pxToRem(24);
}

.title {
  @include fontSize(26, 32);
  font-weight: 800;
  color: var(--tex-def-tie1);
  margin: 0;
}

.carouselContainer {
  padding-bottom: pxToRem(16);
}

.keyInformationContainer {
  padding-bottom: pxToRem(24);
}

.cta {
  width: 100%;
}

@include sm {
  .header {
    padding-bottom: pxToRem(32);
  }

  .carouselContainer {
    padding-bottom: pxToRem(32);
  }

  .keyInformationContainer {
    padding-bottom: pxToRem(32);
  }

  .cta {
    width: auto;
  }
}

@include lg {
  .keyInformationContainer {
    padding-bottom: pxToRem(40);
  }
}
