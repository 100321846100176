@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.form {
  background-color: var(--bac-tie1);
  border-radius: var(--cor_rad-lg);

  :global {
    .MuiTabs-root {
      text-transform: none;
      border-radius: pxToRem(16) pxToRem(16) 0 0;
      background-color: $color-neutral-075;

      .MuiTab-root {
        text-transform: none;
        @include fontSize(15, 20);
        font-weight: 400;
        color: var(--tex-def-tie1);
      }

      .Mui-selected {
        background-color: var(--bac-tie1);
        box-shadow: inset 0 pxToRem(-2) 0 var(--bor-pri-bas);
        border-radius: pxToRem(8) pxToRem(16) 0 0;
        @include fontSize(15, 20);
        color: var(--tex-def-tie1);
        font-weight: 800;
      }

      .MuiTabs-indicator {
        background-color: var(--bor-pri-bas);
        height: pxToRem(3);
      }
    }
  }
}

.contentWrapper {
  padding: pxToRem(24) pxToRem(16) pxToRem(16) pxToRem(16);
}

.layout {
  display: grid;
  row-gap: pxToRem(32);
}

.title {
  @include fontSize(17, 24);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.description {
  @include fontSize(14, 18);
  font-weight: 400;
  color: var(--tex-def-tie2);
}

.inputWrapper {
  display: grid;
}

.buttonsContainer {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-gap: pxToRem(8);

  .termButton {
    border: pxToRem(2) solid $color-neutral-100;
    border-radius: pxToRem(20);
    width: auto;
    box-shadow: none;
    background-color: var(--bac-tie1);

    &.active {
      background-color: $color-uiPrimary-050;
      border: pxToRem(2) solid $color-uiPrimary-400;

      &:hover,
      &:focus {
        background-color: $color-uiPrimary-050;
        border: pxToRem(2) solid $color-uiPrimary-400;
      }
    }
  }
}

.loanBlock {
  display: grid;
  row-gap: pxToRem(8);
}

.interestBlock {
  display: grid;
  grid-template-columns: auto pxToRem(120);
  :global {
    .MuiFormControl-root {
      margin: 0;
    }
  }
}

.paymentBlock {
  border-radius: var(--cor_rad-lg);
  background-color: $color-uiPrimary-050;
  padding: pxToRem(16);
  text-align: center;
  display: grid;
  row-gap: pxToRem(16);
}

.normalText {
  @include fontSize(15, 20);
  font-weight: 400;
  color: var(--tex-def-tie2);
}

.fee {
  @include fontSize(44, 48);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.subFee {
  @include fontSize(26, 32);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

@include sm {
  .form {
    :global {
      .MuiTabs-root {
        .MuiTab-root {
          @include fontSize(17, 24);
        }
      }
    }
  }
  .layout {
    row-gap: pxToRem(24);
  }

  .inputWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: pxToRem(16);
  }

  .paymentBlock {
    grid-template-columns: unset;
    column-gap: pxToRem(16);
  }
}

@include md {
  .layout {
    row-gap: pxToRem(32);
  }

  .contentWrapper {
    padding: pxToRem(24);
  }

  .fee {
    @include fontSize(60, 68);
  }

  .inputWrapper {
    column-gap: pxToRem(24);
  }

  .subFee {
    @include fontSize(32, 40);
  }
}
