@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  display: grid;
  row-gap: pxToRem(24);
}

.header {
  @include fontSize(26, 32);
  font-weight: 800;
  color: var(--tex-def-tie1);
  margin: 0;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: pxToRem(8);
}

.title {
  @include fontSize(20, 28);
  margin: 0;
}

.content {
  background: var(--bac-tie1);
  border-radius: var(--cor_rad-lg);
  padding: pxToRem(16);
  border: $border-2;
}

.switchWrapper {
  margin: 0;
  padding: pxToRem(16) 0 0;
  width: 100%;
  justify-content: space-between;
  display: flex;

  :global(.wrapper--switch) {
    width: 100%;
  }
}

@include md {
  .content {
    padding: pxToRem(32);
  }
}
