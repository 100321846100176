@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.header {
  margin: 0 0 pxToRem(24);
  font-weight: 800;
  @include fontSize(26, 32);
  color: var(--tex-def-tie1);
}

.layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: pxToRem(16);
  column-gap: pxToRem(8);
  margin: 0;
}

// Custom case when the VIN is included in items
.infoWithVin {
  display: grid;
  column-gap: pxToRem(16);
  grid-template-columns: pxToRem(24) auto;

  &:last-child {
    grid-column: span 2;
  }
}

.info {
  display: grid;
  column-gap: pxToRem(16);
  grid-template-columns: pxToRem(24) auto;
}

.infoIcon {
  grid-column: 1;
  grid-row: 1 / span 2;
  svg {
    width: pxToRem(24);
    height: pxToRem(24);
  }
}

.infoName {
  @include fontSize(15, 20);
  font-weight: 400;
  color: rgba(21, 13, 44, 0.7);
  grid-column: 2;
  grid-row: 1;
}

.infoValue {
  margin: 0;
  @include fontSize(17, 24);
  font-weight: 400;
  color: var(--tex-def-tie1);
  grid-column: 2;
  grid-row: 2;
}

@include sm {
  // Custom case when the VIN is included in items
  .infoWithVin {
    &:last-child {
      grid-column: initial;
    }
  }
}

@include md {
  .layout {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: pxToRem(20);
  }
}

@include lg {
  .header {
    margin: 0 0 pxToRem(32);
    @include fontSize(32, 40);
  }
}
