@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.byofSubmitButtons {
  display: flex;
  justify-content: flex-end;
  button {
    width: 100%;
  }
}

@include md {
  .baseModalRoot {
    &:global(.modal) {
      width: 100%;
      max-width: pxToRem(848);
    }
    :global {
      .CheckoutDescription_description__3IsrE {
        display: none;
      }

      .MuiPaper-root {
        width: 632px;
      }
    }
  }

  .byofCarmaFinanceModal {
    width: pxToRem(630);

    header {
      gap: pxToRem(20);
    }

    .byofSubmitButtons {
      button {
        width: unset;
      }
    }
  }
}
