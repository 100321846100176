@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

// Create a dedicated test for the comp

.root {
  font-family: inherit;

  &:global(.toggleButton--rounded) {
    border-radius: #{pxToRem(999)};
  }

  &:global(.toggleButton--squared) {
    border-radius: #{pxToRem(12)};
  }

  &:global(.toggleButton) {
    color: var(--tex-def-tie1);
    background: var(--bac-tie1);
    border: #{pxToRem(1)} solid var(--bor-neu-mut);
    cursor: pointer;

    padding: #{pxToRem(11)} #{pxToRem(8)};
    font-size: #{pxToRem(15)};
    line-height: 70%;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    --shade-gradient-col1: #6b34e029;
    --shade-gradient-col2: #ffffff00;

    :global(.shade:before) {
      display: none;
    }

    &.fullWidth {
      min-width: max-content;
      width: 100%;
    }

    &.disabled {
      cursor: default;

      :global {
        .shade {
          display: none;
        }
      }
    }

    &.isSelected {
      color: var(--tex-def-tie1);
      background: var(--for-pri-lig);
      border-color: var(--bor-pri-bas);
    }

    &::before {
      content: '';
      background: rgba(107, 52, 224, 0);
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: #{pxToRem(0)};
      left: #{pxToRem(0)};
      transition: all 0.2s ease-in-out;
    }

    &:not(:disabled) {
      &:focus-visible,
      &:has(:focus-visible) {
        outline: none;
        text-decoration: none;
        color: var(--tex-def-tie1);
        border-color: var(--bor-pri-bas);
        box-shadow: 0 0 0 pxToRem(1) var(--bor-pri-bas);
        &::before {
          background: rgba(107, 52, 224, 0.16);
        }
      }
    }
  }

  &:global(.toggleGroup) {
    width: 100%;
    gap: pxToRem(8);
    display: flex;

    &.fullWidth {
      width: 100%;
      min-width: max-content;
    }
  }
}
