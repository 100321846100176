@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  overflow: hidden;
  background-color: var(--bac-tie2);
}

.contentWrapper {
  @include rootSection(var(--bac-tie2));
  display: flex;
  flex-flow: column;
  padding: pxToRem(40) pxToRem(20);
}

.topWell {
  display: flex;
  flex-flow: column;
  flex: 1;
  position: relative;
}

.bottomWell {
  flex: 1;
}

.carWrapper {
  position: relative;
  width: pxToRem(203);
  height: pxToRem(82);
  margin-top: pxToRem(48);
}

.headerWrapper {
  margin-bottom: pxToRem(32);
}

.uspWrapper {
  display: flex;
  flex-flow: column;
  gap: pxToRem(28);
  margin-bottom: pxToRem(48);
}

.uspGroup {
  display: flex;
  align-items: center;
  gap: pxToRem(12);
  span {
    width: 75%;
  }
}

.lenderLogoWrapper {
  display: flex;
}

.title {
  @include fontSize(26, 32);
  font-weight: 800;
  margin: 0;
  margin-bottom: pxToRem(20);
}

.subTitle {
  @include fontSize(17, 24);
}

.calWrapper {
  border-radius: var(--cor_rad-lg);
  border: $border-0;
  box-shadow: var(--elevation-0dp);
  margin: pxToRem(20) 0 pxToRem(16);
}

.noteWrapper {
  margin: 0 calc(-50vw + 50% + 16px);
}

p.note {
  @include fontSize(12, 16);
  color: var(--tex-def-tie3);
  text-align: center;
}

.verticalDivider {
  display: none;
}

@include sm {
  .contentWrapper {
    gap: pxToRem(20);
  }

  .noteWrapper {
    margin: 0;
  }

  .title {
    width: 75%;
    @include fontSize(32, 40);
  }

  .uspWrapper {
    display: flex;
    flex-flow: row;
    gap: pxToRem(24);
    margin-bottom: pxToRem(36);
  }

  .uspGroup {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
  .lenderWrapper {
    display: flex;
    flex-flow: row;
    align-items: stretch;
    gap: pxToRem(20);

    .lenderIntro {
      display: flex;
      align-items: center;
      p {
        margin: 0;
      }
    }
    .lenderLogoWrapper {
      align-items: center;
    }

    .verticalDivider {
      display: block;
      background-color: var(--bor-neu-mut);
      width: pxToRem(1);
    }
  }
  .carWrapper {
    margin-top: 0;
    width: pxToRem(402);
    height: pxToRem(163);
  }

  .calWrapper {
    margin-top: 0;
    margin-bottom: pxToRem(16);
  }
}

@include md {
  .contentWrapper {
    flex-flow: row;
    gap: pxToRem(20);
  }

  .topWell {
    justify-content: space-between;
  }

  .uspWrapper {
    display: flex;
    flex-flow: row;
    gap: pxToRem(24);
    margin-bottom: pxToRem(32);
  }

  .uspGroup {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    gap: pxToRem(8);
    span {
      width: 100%;
    }
  }
  .lenderWrapper {
    margin-top: pxToRem(24);
    flex-flow: column;
  }

  .lenderIntro {
    width: pxToRem(138);
  }
  .lenderContent {
    display: flex;
    align-items: stretch;
    gap: pxToRem(16);
  }

  .carWrapper {
    margin-top: pxToRem(95);
    width: pxToRem(457);
    height: pxToRem(185);
  }

  .calWrapper {
    flex: 1;
    z-index: 1;
    margin-bottom: pxToRem(26);
  }

  .title {
    @include fontSize(32, 40);
    width: 100%;
  }

  .subTitle {
    @include fontSize(17, 24);
  }
}

@include mdp {
  .contentWrapper {
    padding-top: pxToRem(56);
    padding-bottom: pxToRem(56);
  }
}

@include lg {
  .contentWrapper {
    gap: pxToRem(116);
  }
  .title {
    @include fontSize(44, 48);
  }
  .subTitle {
    @include fontSize(20, 28);
  }
  .uspWrapper {
    margin-bottom: pxToRem(56);
  }
  .carWrapper {
    width: pxToRem(534);
    height: pxToRem(216);
  }
  .calWrapper {
    margin-bottom: pxToRem(40);
  }
  .noteWrapper {
    transform: scale(1);
  }
}
