@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  :global {
    .modalHeader {
      padding: 16px 24px;
    }

    .modalContentMain {
      padding: 0;
    }
  }
}

.groupContent {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content {
    padding: pxToRem(8) pxToRem(24);
    flex: 1;
  }

  .groupButton {
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    display: flex;
    gap: pxToRem(8);
    padding: 0 pxToRem(16) pxToRem(48);
  }
}

.button {
  width: 100%;
}

@include sm {
  .root {
    width: pxToRem(600);
  }

  .button {
    width: auto;
  }

  .groupContent {
    .groupButton {
      flex-direction: row;
      padding: pxToRem(20);
    }
  }
}
