@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

.root {
  border: $border-0;
  box-shadow: var(--elevation-0dp);
  border-radius: var(--cor_rad-xs);
  position: relative;
  overflow: hidden;
  padding: pxToRem(4);
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template:
    '🚗 ' auto
    '😀' 1fr
    '❤️' pxToRem(28) / 1fr;
  gap: pxToRem(8);
  transition: box-shadow ease-in-out 300ms;

  &:hover,
  &:focus-within {
    box-shadow: var(--elevation-12dp);
  }

  &.sticky {
    grid-template:
      '😀' 1fr
      '❤️' pxToRem(28) / 1fr;

    padding-top: pxToRem(8);

    .image {
      display: none;
    }
  }
}

.image {
  width: 100%;
  height: auto;
  display: block;
  overflow: hidden;
  border-radius: var(--cor_rad-2xs);
  grid-area: 🚗;
}

.content {
  grid-area: 😀;
  padding: 0 pxToRem(8);
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: pxToRem(32);

  :global(.shade:before) {
    content: none;
  }
}

.footer {
  grid-area: ❤️;
  margin: 0 pxToRem(-4) pxToRem(-4);
  border-top: $border-0;

  .footerAction:global(.button) {
    --shade-gradient-col1: var(--sta-ter-hov);
    --shade-gradient-col2: var(--sta-ter-def);
    width: 100%;
    border-radius: 0;
    background: var(--bac-tie1);
  }
}

@container (min-width: #{pxToRem(300)}) {
  .root {
    padding-right: pxToRem(8);
    &,
    &.sticky {
      grid-template:
        '🚗 😀' 1fr
        '🚗 ❤️' auto / pxToRem(156) 1fr;
    }

    &.sticky {
      padding-top: pxToRem(4);
      .image {
        display: block;
      }
    }
  }

  .image {
    height: 100%;
    object-fit: cover;
  }

  .content {
    padding: 0;
  }

  .link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
  }

  .footer {
    border-top: none;
    margin: 0;

    .footerAction:global(.button) {
      width: auto;
      border: $border-0;
      border-radius: var(--cor_rad-xs);
    }
  }
}
