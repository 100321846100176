@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  margin-top: pxToRem(24);
  border-radius: var(--cor_rad-xs);
  padding: pxToRem(16);
  background: $color-uiPrimary-050;
}

.contentContainer {
  display: grid;
}

.content {
  display: grid;
  grid-template: '🚗 😀' auto / pxToRem(48) 1fr;
  gap: pxToRem(24);
  align-items: center;
  order: 1;
}

.badgeIllustration {
  grid-area: 🚗;
  align-self: flex-start;
}

.textContainer {
  h6 {
    margin: 0;
    @include fontSize(17, 24);
  }

  p {
    @include fontSize(15, 20);
  }
}

@include sm {
  .contentContainer {
    grid-template-areas:
      'a b'
      'c c';
  }

  .content {
    grid-area: a;
    order: 1;
  }
}

@include lg {
  .root {
    margin-top: pxToRem(32);
  }
}
