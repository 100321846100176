@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  background-color: var(--bac-tie1);
  border-radius: var(--cor_rad-lg);
  border: $border-0;
  box-shadow: var(--elevation-0dp);
  overflow: hidden;

  :global(.tabs-tablistWrapper) {
    background: var(--bac-tie2);
  }

  :global(.tabs-tab) {
    width: 50%;
  }

  :global(.tabs-tab--active) {
    background: var(--bac-tie1);
  }

  :global(.tabs-tab-label) {
    @include fontSize(17, 24);
  }
}

.form {
  padding: pxToRem(24);
}

.contentWrapper {
  padding: pxToRem(24) pxToRem(16) pxToRem(16) pxToRem(16);
}

.layout {
  display: grid;
  row-gap: pxToRem(32);
}

.title {
  @include fontSize(17, 24);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.description {
  @include fontSize(14, 18);
  font-weight: 400;
  color: var(--tex-def-tie2);
}

.inputWrapper {
  display: grid;
}

.loanBlock {
  display: grid;
  row-gap: pxToRem(8);
}

.interestBlock {
  display: grid;
  grid-template-columns: auto pxToRem(120);
  :global {
    .MuiFormControl-root {
      margin: 0;
    }
  }
}

.paymentBlock {
  border-radius: var(--cor_rad-lg);
  background-color: $color-uiPrimary-050;
  padding: pxToRem(16);
  text-align: center;
  display: grid;
  row-gap: pxToRem(16);
}

.normalText {
  @include fontSize(15, 20);
  font-weight: 400;
  color: var(--tex-def-tie2);
}

.fee {
  @include fontSize(44, 48);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.subFee {
  @include fontSize(26, 32);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

@include sm {
  .layout {
    row-gap: pxToRem(24);
  }

  .inputWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: pxToRem(16);
  }

  .paymentBlock {
    grid-template-columns: unset;
    column-gap: pxToRem(16);
  }
}

@include md {
  .layout {
    row-gap: pxToRem(32);
  }

  .fee {
    @include fontSize(60, 68);
  }

  .inputWrapper {
    column-gap: pxToRem(24);
  }

  .subFee {
    @include fontSize(32, 40);
  }
}
