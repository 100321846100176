@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.priceInitial,
.feeItem,
.priceTotal {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.priceInitialValue,
.feeItemValue,
.priceTotalValue {
  text-align: right;
  margin: 0;
}

.priceInitial,
.feeItem {
  @include fontSize(15, 20);
}

.priceInitial {
  margin: 0;
  padding: 0;
}

.priceInitialValueNote {
  @include fontSize(14, 18);
  color: var(--tex-def-tie3);
  display: block;
}

.fees {
  border: $border-0;
  border-radius: var(--cor_rad-xs);
  margin: pxToRem(16) 0;
  padding: pxToRem(16);
}

.feesTitle {
  @include fontSize(15, 20);
  margin: 0 0 pxToRem(16);
}

.feesList {
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: pxToRem(8);
}

.feeItemLabel {
  &:before {
    content: '+ ';
  }
}

.priceTotal {
  @include fontSize(18, 24);
  font-weight: 800;
  margin: pxToRem(24) 0;
}

.footer {
  @include fontSize(14, 18);
  color: var(--tex-def-tie3);
}
