@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.trigger {
  appearance: none;
  border: none;
  margin: 0;
  display: block;
  text-align: left;
  width: 100%;
  padding: pxToRem(6) pxToRem(8);
  border-radius: var(--cor_rad-xs);
  color: var(--tex-def-tie1);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  font-family: var(--font-default);
  @include fontSize(15, 18);
  font-weight: 600;
  --shade-gradient-col1: var(--whi-200);
  --shade-gradient-col2: var(--whi-100);

  :global(.shade:before) {
    display: none;
  }

  &:hover,
  &:focus-visible {
    :global(.shade:before) {
      display: block;
    }
  }

  + .trigger {
    margin-top: pxToRem(8);
  }
}

.triggerContent {
  pointer-events: none;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  column-gap: pxToRem(6);

  :global(.icon) {
    font-size: pxToRem(18);
  }
}

.iconOnly {
  width: fit-content;
}

.modal {
  :global {
    .modalContentMain {
      padding-bottom: pxToRem(24);

      h1,
      h2,
      h3 {
        &:first-child {
          margin-top: 0;
        }
      }

      h1 {
        margin-top: pxToRem(24);
        margin-bottom: pxToRem(8);
      }

      h2 {
        margin-top: pxToRem(20);
        margin-bottom: pxToRem(6);
      }

      h3 {
        margin-top: pxToRem(16);
        margin-bottom: pxToRem(4);
      }

      p + p {
        margin-top: pxToRem(24);
      }
    }

    .mediaSection,
    .markdownCarousel {
      --section-vertical-padding: #{pxToRem(24)};
      padding-left: 0;
      padding-right: 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    .markdownCarousel {
      margin-left: pxToRem(-16);
      margin-right: pxToRem(-16);

      .carouselTrack {
        padding-left: pxToRem(16);
        padding-right: pxToRem(16);
        margin-left: 0;
        margin-right: 0;
      }

      .carouselTile {
        flex: 0 0 85%;
      }

      .carouselControls {
        $controlsGradientOffset: 0;
        $controlGradientWidth: pxToRem(40);
        &::before {
          left: $controlsGradientOffset;
          width: $controlGradientWidth;
        }
        &::after {
          right: $controlsGradientOffset;
          width: $controlGradientWidth;
        }
      }
    }

    .media {
      img,
      .video {
        border-radius: var(--cor_rad-xs);
      }
    }
  }
}
