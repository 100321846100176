@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

@keyframes fadeAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalAppear {
  from {
    opacity: 0;
    transform: translateY(pxToRem(-16));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.root {
  --max-height: calc(100dvh - #{pxToRem(32)});
  max-width: pxToRem(480);
  width: calc(100% - #{pxToRem(32)});
  height: fit-content;
  // Intentionally duplicating the max-height properties as fallback for the `dvh` unit
  max-height: calc(100% - #{pxToRem(32)});
  max-height: var(--max-height);
  border: 0;
  padding: 0;
  top: 0;
  left: 0;
  position: fixed;
  animation: modalAppear 200ms ease-in-out;
  margin: auto;
  border-radius: var(--cor_rad-lg);
  transform-origin: top center;
  box-shadow: var(--elevation-8dp);

  + :global(.backdrop), // Styles for the dialog polyfill
  &::backdrop {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: -1;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: $color-overlay;
    -webkit-tap-highlight-color: transparent;
    animation: fadeAppear 200ms ease-in-out;
    backdrop-filter: saturate(50%);
  }

  :global(.modalFooter) {
    padding-top: pxToRem(12);
    display: flex;
    flex-direction: column;
    gap: pxToRem(8);
    width: 100%;
  }
}

.content {
  padding: pxToRem(0);
  @include fontSize(17, 25);
  color: var(--tex-def-tie1);
  display: grid;
  grid-template-rows: auto 1fr;
  height: auto;
  max-height: var(--max-height);
  word-break: break-word;

  &.withFooter {
    grid-template-rows: auto 1fr auto;
    padding-bottom: pxToRem(12);
  }

  &.hideHeader {
    grid-template-rows: 1fr;

    .header {
      position: absolute;
      top: pxToRem(-999);
    }

    &.withFooter {
      grid-template-rows: 1fr auto;
    }
  }
}

.header {
  padding: pxToRem(16) pxToRem(12);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  gap: pxToRem(8);
  word-break: break-word;
}

.headerTop {
  display: block;
  width: 100%;
  padding-right: pxToRem(48);
  position: relative;

  :global(.modalClose) {
    position: absolute;
    top: pxToRem(-6);
    right: pxToRem(-2);
  }
}

.title {
  margin: 0;
  @include fontSize(21, 28);
  font-weight: 800;
  flex: 1;
}

.contentMain {
  padding: 0 pxToRem(12) pxToRem(16);
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: thin solid transparent;
  border-bottom: thin solid transparent;

  > form:first-child {
    padding-top: pxToRem(4);
  }

  &:global.overflow-top {
    border-top-color: $color-neutral-150;
  }
}

.withFooter {
  .footer {
    padding: pxToRem(12) pxToRem(12) 0;
  }
  .contentMain {
    &:global.overflow-bottom {
      border-bottom-color: $color-neutral-150;
    }
  }
}

@include sm {
  .root {
    --max-height: calc(100dvh - #{pxToRem(32)});

    :global(.modalFooter) {
      padding-top: pxToRem(16);
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: flex-start;
      column-gap: pxToRem(8);
      width: 100%;
    }
  }

  .content {
    height: auto;
    max-height: calc(100vh - #{pxToRem(32)});

    &.withFooter {
      padding-bottom: pxToRem(16);
    }
  }

  .header {
    padding: pxToRem(20) pxToRem(16);
  }

  .headerTop {
    :global(.modalClose) {
      top: pxToRem(-4);
      right: 0;
    }
  }

  .title {
    @include fontSize(26, 32);
  }

  .contentMain {
    padding-left: pxToRem(16);
    padding-right: pxToRem(16);
  }

  .withFooter {
    .footer {
      padding: pxToRem(16) pxToRem(16) 0;
    }
  }
}
