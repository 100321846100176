@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  overflow: hidden;
  padding: pxToRem(4) 0;
  margin: pxToRem(-4) 0;
}

.chipsSizingTrack,
.chipsTrack {
  display: flex;
  column-gap: pxToRem(8);
  row-gap: pxToRem(8);
  white-space: nowrap;
  width: fit-content;
}

.chipsSizingTrack {
  height: 0;
  overflow: hidden;
}

.initialised.showAll .chipsTrack {
  flex-wrap: wrap;
  width: auto;
}
