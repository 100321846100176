@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  padding: calc(#{pxToRem(16)} + $headerHeight) pxToRem(16) pxToRem(16);
  max-width: pxToRem(1800);
  margin: 0 auto;
  @include fullWidthBackgroundColor(var(--bac-tie2));
}

.sidebar {
  display: none;
}

@include mdp {
  .root {
    padding: calc(#{pxToRem(24)} + $headerHeight) pxToRem(32) pxToRem(24);
    display: grid;
    // Intentionally made the sidebar wider (300px in designs)
    // to avoid overlapping issue with ANCAP rating filter notification
    grid-template: '🍍 🥥' 1fr / pxToRem(316) calc(100% - #{pxToRem(316 + 24)});
    column-gap: pxToRem(24);
  }

  .sidebar {
    display: block;
    grid-area: 🍍;
  }

  .main {
    grid-area: 🥥;
  }
}

@include xl {
  .root {
    padding-left: pxToRem(72);
    padding-right: pxToRem(72);
    max-width: pxToRem(1744);
    grid-template-columns: pxToRem(370) calc(100% - #{pxToRem(370 + 40)});
    column-gap: pxToRem(40);
  }
}
