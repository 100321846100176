@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  padding-bottom: pxToRem(16);
  margin-top: pxToRem(-8);
}

.actionComponent {
  display: none;
}

@include md {
  .root {
    pointer-events: none;
    position: sticky;

    top: -1px; // Used as part of the pinned intersection observer technique
    z-index: 1;
    padding: calc($headerHeight + pxToRem(24)) pxToRem(24) pxToRem(16);
    margin: calc(pxToRem(-32) - ($headerHeight)) pxToRem(-24) 0;
    width: calc(100% + #{pxToRem(24 * 2)});
    display: grid;
    grid-template: '😀 ✅' auto / calc(100% - #{pxToRem(162 + 16)}) #{pxToRem(162)};
    column-gap: pxToRem(16);

    &.isPinned {
      background: var(--bac-tie2);
    }

    &.isFooterVisible {
      position: relative;
    }

    > div {
      pointer-events: auto;
    }
  }

  .content {
    grid-area: 😀;
  }

  .actionComponent {
    display: block;
    grid-area: ✅;
    a {
      width: 100%;
    }
  }
}

@include mdp {
  .root {
    &:before {
      content: '';
      position: absolute;
      left: pxToRem(8);
      right: pxToRem(8);
      bottom: 0;
      border-bottom: $border-0;
      opacity: 0;
      transition: opacity ease-in-out 200ms;
    }

    &.isPinned {
      &:before {
        opacity: 1;
      }
    }
  }
}
