@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

.root {
  font-weight: 800;
  padding: pxToRem(0);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.card {
  border: $border-2;
  border-radius: var(--cor_rad-lg);
  background: var(--bac-tie1);
  padding: pxToRem(16);
  margin-bottom: pxToRem(32);
  width: 100%;
}

.cardNoPurchased {
  border: $border-2;
  border-radius: var(--cor_rad-lg);
  background: var(--bac-tie1);
  margin-bottom: pxToRem(32);
  width: 100%;
}

.cardContent {
  display: flex;
  flex-direction: column;
}

.cardNoPurchasedContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: pxToRem(16);
}

.cardNoPurchasedIcon {
  margin: pxToRem(20) 0;
}

.lineBreak {
  border-top: $border-0;
  margin-top: pxToRem(40);
}

.subtitle {
  @include fontSize(15, 20);
  font-weight: 400;
}

.cardLeftContent {
  padding: pxToRem(10);
}

.carImg {
  width: 100%;
  height: auto;
  border-radius: pxToRem(8);
}

.deliverySchedule {
  @include fontSize(12, 20);
  width: fit-content;
  border: $border-2;
  border-radius: pxToRem(20);
  padding: pxToRem(8) pxToRem(12);
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: pxToRem(8);
  pointer-events: none;
  letter-spacing: 0.1em;
  background-color: var(--blu-100);
}

.title {
  @include fontSize(26, 32);
}

.bold {
  font-weight: 800;
}

.priceLine {
  display: flex;
  justify-content: left;
}

.colorPrimary {
  color: $color-uiPrimary-600;
  padding-left: pxToRem(8);
  cursor: pointer;
  text-decoration: underline;
}

.icon {
  margin-right: pxToRem(10);
}

.purchaseDetailButton {
  min-width: pxToRem(222);
  width: 100%;
  margin: pxToRem(4);
}

.menuButton {
  width: 100%;
  height: auto;
  padding: pxToRem(4) 0;
}

.buttonBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.paymentOption {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@include md {
  .root {
    padding: pxToRem(16);
  }

  .card {
    padding: pxToRem(32);
    padding-bottom: pxToRem(26);
  }

  .cardContent {
    flex-direction: row;
    justify-content: space-between;
  }

  .carImg {
    width: auto;
    height: pxToRem(150);
  }

  .menuButton {
    width: fit-content;
    padding: 0 pxToRem(4);
  }

  .paymentOption {
    flex-direction: row;
  }

  .buttonBox {
    flex-direction: row;
  }

  .purchaseDetailButton {
    width: pxToRem(222);
  }

  .cardNoPurchasedContent {
    padding: pxToRem(24) pxToRem(16);
  }

  .cardLeftContent {
    margin-bottom: pxToRem(0);
  }
}
