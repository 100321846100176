@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.item {
  border-bottom: $border-0;
  padding: pxToRem(16) 0;
  display: grid;
  grid-template: '😀 🎛️' auto / 1fr auto;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.list {
  grid-area: 😀;
  margin: 0;
  padding: 0;
  align-self: center;
}

.listTerm {
  font-weight: 800;
  margin-top: pxToRem(8);

  &:first-child {
    margin-top: 0;
  }
}

.listValue {
  margin: 0;
  padding: 0;

  p {
    margin-bottom: pxToRem(8);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.actions {
  .buttonSmall {
    margin-top: pxToRem(8);
  }
  .buttonLarge {
    display: none;
  }
}

@include md {
  .list {
    display: grid;
    grid-template-columns: pxToRem(80) 1fr;
    gap: pxToRem(8);
  }

  .listTerm {
    margin-top: 0;
  }

  .actions {
    .buttonSmall {
      display: none;
    }
    .buttonLarge {
      display: block;
    }
  }
}
