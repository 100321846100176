@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.form {
  background-color: var(--bac-tie1);
  border-radius: var(--cor_rad-lg);

  :global {
    .MuiTabs-root {
      text-transform: none;
      border-radius: pxToRem(16) pxToRem(16) 0 0;
      background-color: $color-neutral-075;

      .MuiTab-root {
        text-transform: none;
        @include fontSize(15, 20);
        font-weight: 400;
        color: var(--tex-def-tie1);
      }

      .Mui-selected {
        background-color: var(--bac-tie1);
        box-shadow: inset 0 pxToRem(-2) 0 $color-uiPrimary-800;
        border-radius: pxToRem(8) pxToRem(16) 0 0;
        @include fontSize(15, 20);
        color: var(--tex-def-tie1);
        font-weight: 800;
      }

      .MuiTabs-indicator {
        background-color: $color-uiPrimary-800;
        height: pxToRem(3);
      }
    }
  }
}

.contentWrapper {
  padding: pxToRem(24) pxToRem(16) pxToRem(16) pxToRem(16);
}

.layout {
  display: grid;
  row-gap: pxToRem(32);
}

.title {
  @include fontSize(17, 24);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.description {
  @include fontSize(14, 18);
  font-weight: 400;
  color: var(--tex-def-tie2);
}

.inputWrapper {
  display: grid;

  :global {
    .MuiFormControl-root .MuiFormLabel-root {
      @include fontSize(14, 18);
      letter-spacing: pxToRem(0.2);
    }

    .MuiInputBase-root .MuiInputBase-input:not(.MuiInputBase-inputMultiline) {
      @include fontSize(17, 24);
    }
  }
}

.buttonsContainer {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-gap: pxToRem(8);

  .termButton {
    border: pxToRem(2) solid var(--bor-neu-mut);
    border-radius: pxToRem(20);
    width: auto;
    box-shadow: none;
    background-color: var(--bac-tie1);
    font-weight: 600;
    color: var(--tex-def-tie1);

    &.active {
      background-color: var(--for-pri-lig);
      border: pxToRem(2) solid var(--bor-pri-bas);

      &:hover,
      &:focus {
        border: pxToRem(2) solid var(--bor-pri-bas);
        background-color: var(--for-pri-lig);
      }
    }
  }
}

.loanBlock {
  display: grid;
  row-gap: pxToRem(8);
}

.interestBlock {
  display: grid;
  grid-template-columns: auto pxToRem(120);
  :global {
    .MuiFormControl-root {
      margin: 0;
    }
  }
}

.paymentBlock {
  border-radius: var(--cor_rad-lg);
  background-color: var(--bac-tie2);
  padding: pxToRem(16);
  text-align: center;
  display: grid;
  row-gap: pxToRem(16);
}

.normalText {
  margin-bottom: pxToRem(4);
  color: var(--tex-def-tie2);
}

.fee {
  color: var(--tex-def-tie1);
  max-width: 100%;
}

.subFee {
  color: var(--tex-def-tie1);
}

@include sm {
  .form {
    :global {
      .MuiTabs-root {
        .MuiTab-root {
          @include fontSize(17, 24);
        }
      }
    }
  }
  .layout {
    row-gap: pxToRem(24);
  }

  .inputWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: pxToRem(16);
  }

  .paymentBlock {
    grid-template-columns: repeat(2, 1fr);
    column-gap: pxToRem(16);
    align-items: center;

    a {
      max-height: pxToRem(56);
    }
  }
}

@include md {
  .layout {
    row-gap: pxToRem(32);
  }

  .inputWrapper {
    column-gap: pxToRem(24);
  }

  .paymentBlock {
    grid-template-columns: unset;
    gap: pxToRem(24);

    a {
      max-height: auto;
    }
  }
}
