@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.cardContainer {
  display: flex;
  flex-direction: column;
  gap: pxToRem(12);
  align-self: stretch;
}

.listContainer {
  display: flex;
  flex-direction: column;
  gap: pxToRem(8);
}

.cardHeader {
  @include fontSize(17, 24);
  color: var(--tex-def-tie1);
  font-weight: 800;
  margin: 0;
}

.listItem {
  display: flex;
  justify-content: space-between;
  gap: pxToRem(8);
}

.label {
  flex-basis: 40%;
  display: flex;
}

.labelText {
  color: var(--tex-def-tie2);
  font-style: normal;
  @include fontSize(15, 20);
}

.value {
  display: flex;
  flex-basis: 50%;
  justify-content: right;
  align-items: center;
  text-align: right;
}

.valueText {
  color: var(--tex-def-tie1);
  font-style: normal;
  @include fontSize(15, 20);
}
