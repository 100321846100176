@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;

.form {
  display: flex;
  flex-direction: column;
  gap: pxToRem(24);

  :global {
    .MuiFormGroup-root {
      display: grid;
      gap: pxToRem(16);
      justify-content: space-between;
      grid-template-columns: 1fr 1fr;
    }

    .MuiFormControl-root {
      margin: pxToRem(12) 0 0;

      .MuiFormLabel-root {
        display: none;
      }
    }

    .Mui-error {
      margin-top: pxToRem(12);
      @include fontSize(14, 18);
      font-family: inherit;
    }
  }
}

.formWrapper {
  padding: pxToRem(24);
}

.radioItemTitle {
  @include fontSize(17, 24);
  font-weight: 800;
  margin: 0 0 pxToRem(4);
  color: var(--tex-def-tie1);
}

.radioItemDescription {
  @include fontSize(14, 18);
  font-weight: 400;
  margin: 0;
  color: var(--tex-def-tie2);
}

.holdCarTextContainer {
  display: flex;
  gap: pxToRem(4);
  align-items: center;
  justify-content: center;
  background-color: var(--gre-050);
  padding: pxToRem(3) pxToRem(12) pxToRem(5);
  margin: pxToRem(0) auto pxToRem(16);
}

div .holdCarText {
  @include fontSize(14, 18);
  font-weight: 800;
  color: var(--tex-suc-tie1);
}

svg.iconClock {
  padding: pxToRem(2);
  width: pxToRem(20);
  height: pxToRem(20);
  fill: var(--whi-000);
  background-color: var(--gre-500);
  border-radius: pxToRem(999);
}

.radioWrapper {
  display: flex;
}

.radios {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  padding: pxToRem(12) 0 0 !important;
  column-gap: pxToRem(8);
  @include fontSize(17, 24);

  :global {
    .note {
      grid-column: span 2;
    }
  }
}

@include sm {
  .formWrapper {
    padding: 0;
  }

  .form {
    gap: pxToRem(16);

    :global {
      .MuiFormGroup-root {
        gap: pxToRem(8);
      }
    }
  }

  .holdCarTextContainer {
    max-width: max-content;
    margin: pxToRem(0) auto pxToRem(16);
  }
}
