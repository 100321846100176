@use 'src/theme/helpers' as *;
@use 'src/theme/color.module.scss' as *;
@use 'src/theme/breakpoints.module' as *;

.container {
  display: flex;
  flex-direction: column;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: pxToRem(8);
}

@include md {
  .container {
    flex-direction: column-reverse;
  }

  .tags {
    margin-top: pxToRem(24);
  }
}
