@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.sticky {
  position: fixed;
  top: $headerHeight; // height of the header menu
  left: 0;
  width: 100%;

  transform: translate3d(0, calc($headerHeight * -1), 0);
  opacity: 0;
  pointer-events: none;
  z-index: 999; // Lower than the header menu z-index
  transition:
    transform ease-in-out 200ms,
    opacity ease-in-out 200ms;
}

.content {
  background: var(--for-neu-tie2);
  box-shadow: var(--elevation-1dp);
  padding: pxToRem(16);
  display: grid;
  row-gap: pxToRem(16);
}

.savedSearch {
  position: fixed;
  bottom: pxToRem(24);
  left: 50%;
  transform: translate3d(-50%, pxToRem(80), 0);
  z-index: 999; // Lower than the header menu z-index
  opacity: 0;
  pointer-events: none;
  transition:
    transform ease-in-out 200ms,
    opacity ease-in-out 200ms;

  > label {
    box-shadow: var(--elevation-2dp);
  }
}

:global(body:has(.carComparisonBanner)) {
  .savedSearch {
    bottom: pxToRem(72);
  }
}

:global(.scroll-up) {
  .sticky.visible {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    pointer-events: auto;
  }
  .savedSearch {
    pointer-events: auto;
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }
}

:global(.scroll-up) .sticky:not(.visible) {
  transform: translate3d(0, 0, 0);
  opacity: 0;
  pointer-events: none;
  transition: none;
}

@include md {
  .sticky,
  .savedSearch {
    display: none;
  }
}
