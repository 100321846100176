@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;

.container {
  width: 100%;
  height: pxToRem(32);
  display: grid;
  row-gap: pxToRem(8);
  p {
    margin: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  @include fontSize(17, 24);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.description {
  @include fontSize(14, 18);
  font-weight: 400;
  color: var(--tex-def-tie2);
}

.slider {
  padding: 0 pxToRem(18);
  border-radius: pxToRem(40);
  background-color: var(--for-pri-sub);
  box-shadow: var(--elevation-0dp);
  height: pxToRem(32);
  overflow: hidden;

  :global {
    .MuiSlider-root {
      margin: 0;
      padding: pxToRem(15) 0;
    }

    .MuiSlider-rail {
      background: transparent;
    }

    .MuiSlider-track {
      height: pxToRem(26);
      background: var(--bac-tie1);
      border: $border-2;
      box-shadow: var(--elevation-0dp);
      margin: pxToRem(-13) 0;
      border-radius: pxToRem(14);
      padding: 0 pxToRem(13);
      transform: translateX(pxToRem(-14));
    }

    .MuiSlider-mark {
      width: pxToRem(5);
      height: pxToRem(5);
      border-radius: 50%;
      background-color: $color-uiPrimary-600;
      margin: pxToRem(-1.5);

      &.MuiSlider-markActive {
        background-color: var(--for-pri-emp);
      }
    }

    .MuiSlider-markLabel {
      display: none;
    }

    .MuiSlider-thumb {
      background-color: var(--for-pri-bas);
      width: pxToRem(20);
      height: pxToRem(20);
      margin: pxToRem(-9) pxToRem(-10);

      &.Mui-focusVisible,
      &:hover {
        box-shadow: none;
      }
    }
  }
}
