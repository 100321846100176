@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

.root {
  :global {
    .accordion {
      padding-left: 0;
      padding-right: 0;
    }
    .accordionContent {
      padding-left: pxToRem(16);
      padding-right: pxToRem(16);
    }
  }
}

.root,
.tooltipContent {
  :global {
    .label {
      font-weight: 400;
      width: 100%;
    }

    .wrapper--checkbox {
      row-gap: pxToRem(4);
      padding: 0;

      .labelWrapper {
        padding: pxToRem(8) pxToRem(10);
        border-radius: var(--cor_rad-xs);
        margin-left: pxToRem(-10);
        margin-right: pxToRem(-10);

        &:hover {
          background: var(--sta-on_bac-hov);
        }

        &.indeterminate,
        &.checked {
          background: var(--for-pri-lig);
        }
      }
    }

    [data-state='open'] .labelWrapper {
      background: var(--sta-on_bac-hov);
    }

    .wrapper {
      margin-bottom: 0;
    }

    .selectWrapper {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.filterName,
.filterSwitchWrapper {
  display: grid;
  grid-template: '⚽️ 🏏' 1fr / pxToRem(24) 1fr;
  column-gap: pxToRem(16);
}

.filterNotification {
  position: absolute;
  display: block;
  background-color: var(--for-pri-acc);
  width: pxToRem(8);
  height: pxToRem(8);
  border-radius: var(--cor_rad-ful);
  right: pxToRem(52);
  top: 50%;
  margin-top: pxToRem(-4);
}

.filterCheckboxBody {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterCheckboxImage {
  width: pxToRem(48);
  height: pxToRem(32);
  margin-top: pxToRem(-18);
  margin-bottom: pxToRem(-14);

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
}

.filterSwitchWrapper {
  padding: 0 pxToRem(16);
  min-height: pxToRem(72);

  :global(.icon) {
    align-self: center;
  }
}

.filterSwitch {
  align-self: center;

  :global(.label) {
    @include fontSize(17, 24);
    font-weight: 800;
  }
}

.filterModalButtonWrapper {
  margin-top: pxToRem(16);
}

//////////////
/// Styles for filters hierarchy
//////////////
.parentOption {
  position: relative;

  [data-state='open'] +,
  &:hover {
    :global(.chevron) {
      --chevron-bg-color: #{var(--tex-def-tie1)};
      --chevron-color: #{$color-neutral-000};
    }
  }

  :global(.chevron) {
    position: absolute;
    top: pxToRem(8);
    right: pxToRem(8);
  }
}

.tooltipContent {
  background: var(--bac-tie1);
  z-index: $zIndex-modal;
  padding: pxToRem(8) pxToRem(16);
  border-radius: var(--cor_rad-xs);
  margin: pxToRem(-8);
  box-shadow: var(--elevation-12dp);
  min-width: pxToRem(260);
  max-height: calc(100vh - #{pxToRem(24)});
  overflow-y: auto;
}

.tooltipTitle {
  @include fontSize(17, 24);
  margin: 0;
  padding: pxToRem(8) 0;
}

.modalHierarchyTrigger {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: pxToRem(64);
  border: none;
  appearance: none;
  background: transparent;
  padding: 0;
  margin: 0;
}
//////////////
/// END Styles for filters hierarchy
//////////////
