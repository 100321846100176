@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

.trigger {
  margin: pxToRem(-11) pxToRem(-8);
}

.triggerIcon {
  width: pxToRem(18);
  height: pxToRem(18);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--cor_rad-ful);
  background: var(--ind-100);
  margin-left: pxToRem(4);
}

.content {
  background: var(--bac-tie2);
  border-radius: var(--cor_rad-xs);
  padding: pxToRem(16);
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: pxToRem(16);
}

.header {
  display: grid;
  grid-template: '😀 🎛️' auto / pxToRem(56) 1fr;
  column-gap: pxToRem(24);
}

.headerIllustration {
  grid-area: 😀;
  align-self: center;
  width: pxToRem(56);
  height: pxToRem(56);
}

.headerContainer {
  grid-area: 🎛️;
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: pxToRem(4);
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.listItem {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: $border-0;
  column-gap: pxToRem(8);
  padding: pxToRem(22) 0;

  &:last-child {
    border-bottom: none;
    padding-bottom: pxToRem(6);
  }
}
