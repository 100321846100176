@use 'src/theme/helpers' as *;
@use 'src/theme/color.module.scss' as *;

.root {
  :global {
    .wrapper--checkbox:not(.wrapper--checkbox-none) {
      margin-bottom: 0;
      .note.error {
        display: none;
      }
    }
  }
  hr {
    margin: pxToRem(12) 0;
  }
}
